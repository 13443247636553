.footer {
    position:relative;
    bottom: 0;
    max-width: 100%;
    height: 10rem;  
    background-color: rgb(136, 136, 136);
    font-family: $secondary-font;
  
    & p {
        display:grid;
        padding: 0.5rem;
        justify-content: center;
        color: $secondary-color;
    }
    & li {
        padding: 0.8rem;
    }
    
    &_icons {
        display: flex;
        flex-direction: row;
        padding: 1rem;
        justify-content: center;
    }
}