.gradient {
    position: absolute;
    z-index: -1;
    display:contents;
    width:50vh;
    height:50vh;
  }
  
  .gradient .circle-gradient {
    display:block;
    position: absolute;
    z-index: -1;
    width: 20rem;
    height:20rem;
    background: radial-gradient($purple-color, white);
    border-radius:50%;
  }
  
  .gradient .circle-gradient:nth-child(1) {
    bottom:0;
    left:-10rem;;
    opacity: 20%;
  }
  
  .gradient .circle-gradient:nth-child(2) {
    
    top: 0rem;
    right:-10rem;
    opacity: 20%;
  }
  
.front_picture {
    position: relative;
}

.point {
    position: absolute;
    z-index: -1;
    display:contents;
}

.point .point-item {
    position: absolute;
    width: 0.5rem;
    height:0.5rem;
    background: $purple-color;
    border-radius:50%;

}

.point .point-item:nth-child(1) {
    display:block;
    top:10%;
    left:0%;
    background-color:$purple-color; 
}

.point .point-item:nth-child(2) {
    display:block;
    top:9%;
    right:5%;
    background-color:$extra-color; 
  }

.point .point-item:nth-child(3) {
    display:block;
    width: 0.7rem;
    height:0.7rem;
    bottom:10%;
    right:2%;
    background-color:$purple-color; 
}

.point .point-item:nth-child(4) {
    display:block;
    width: 1rem;
    height:1rem;
    bottom:1%;
    left:22%;
    background-color:$extra-color; 
}

.point .point-item:nth-child(5) {
    display:block;
    width: 0.9rem;
    height:0.9rem;
    bottom:9%;
    left:2%;
    background-color:$purple-color; 
}


.point-contact {
    position: absolute;
    z-index: -1;
    display:contents;
}

.point-contact .point-contact-item {
    position: absolute;
    width: 0.5rem;
    height:0.5rem;
    background: $purple-color;
    border-radius:50%;

}

.point-contact .point-contact-item:nth-child(1) {
    display:block;
    top:30%;
    left:10%;
    background-color:$purple-color; 
}

.point-contact .point-contact-item:nth-child(2) {
    display:block;
    top:40%;
    right:5%;
    background-color:$extra-color; 
  }

.point-contact .point-contact-item:nth-child(3) {
    display:block;
    width: 0.7rem;
    height:0.7rem;
    bottom:25%;
    right:20%;
    background-color:$purple-color; 
}

.point-contact .point-contact-item:nth-child(4) {
    display:block;
    width: 1rem;
    height:1rem;
    bottom:5%;
    left:50%;
    background-color:$extra-color; 
}

.point-contact .point-contact-item:nth-child(5) {
    display:block;
    width: 0.9rem;
    height:0.9rem;
    bottom:9%;
    left:2%;
    background-color:$purple-color; 
}