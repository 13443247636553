.scroll-container {
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 0.7rem; 
    background: #ffffff;
}
  
.scroll-indicator {
    height: 0.7rem;
    background: radial-gradient(circle, $accent-color 0%, $extra-color 100%);
    width: 0%;
}